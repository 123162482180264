.navbar {
  height: 5vh;
}
.google-map {
  height: 95vh;
}
.logo {
  height: 3vh;
  padding-left: 1rem;
}

@media (max-height: 1024px) {
  .navbar {
    height: 10vh;
  }
  .google-map {
    height: 90vh;
  }
  .logo {
    height: 4vh;
    padding-left: 1rem;
  }
}

.navItem {
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.fade_in {
  animation: fadeIn 7s;
  -webkit-animation: fadeIn 7s;
  -moz-animation: fadeIn 7s;
  -o-animation: fadeIn 7s;
  -ms-animation: fadeIn 7s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
